import React, { useState } from "react";
import { GrpcWebFetchTransport } from "@protobuf-ts/grpcweb-transport";

import { RepositoryContext, RepositoryContextType } from "./RepositoryContext";
import {
  BuilderClient,
  DownloaderClient,
  RepositoryClient,
} from "../../../proto/internaltool";

import { ResponseMessage } from "../AuthContext/AuthContext";

interface ContextProps {
  repositoryID: string;
  children: React.ReactNode;
}

export const RepositoryContextProvider = (props: ContextProps) => {
  const [branches, setBranches] = useState({});
  const [message, setMessage] = useState<ResponseMessage>({
    type: undefined,
    text: undefined,
  });
  const [loading, setLoading] = useState(false);

  let transport = new GrpcWebFetchTransport({
    format: "binary",
    baseUrl: "https://internaltools-gateway-ft3ogbbfma-ew.a.run.app:443",
  });

  const requestBranches = async () => {
    const client = new RepositoryClient(transport);

    try {
      const branches = await client.listBranches({
        pageSize: 5,
        pageToken: "",
        parent: props.repositoryID,
      });

      setBranches(branches.response.branches);
    } catch (error) {
      console.error(error.message);
    }
  };

  const deployDashboard = async (
    type: string,
    branch: string,
    previewName?: string,
    expireTime?: string
  ) => {
    setLoading(true);

    const buildClient = new BuilderClient(transport);

    try {
      await buildClient.createBuild({
        product: "dashboard",
        buildOptions: {
          branch,
          version: "",
          production: type === "live",
          expiryTime: expireTime || "",
          previewChannel: previewName || "",
        },
      });

      setMessage({
        type: "success",
        text: "Deployment was succesfully done!",
      });
      setLoading(false);

      setTimeout(() => {
        setMessage({
          type: undefined,
          text: undefined,
        });
      }, 5000);
    } catch (error) {
      console.error(error);

      setMessage({
        type: "danger",
        text: error.message,
      });
      setLoading(false);
    }
  };

  const downloadRDXVersion = async () => {
    const client = new DownloaderClient(transport);

    try {
      const response = await client.listReleases({
        pageSize: 5,
        pageToken: "",
        product: "rdx",
        channel: "release",
        parent: props.repositoryID,
      });

      return response.response.releases[0];
    } catch (error) {
      console.error(error);
    }
  };

  const context: RepositoryContextType = {
    loading,
    message,
    branches,
    requestBranches,
    deployDashboard,
    downloadRDXVersion,
  };

  return (
    <RepositoryContext.Provider value={context}>
      {props.children}
    </RepositoryContext.Provider>
  );
};

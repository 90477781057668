import React from "react";

import { PageTable } from "../../components/shared/PageTable/PageTable";

import { DeployedLinks } from "../../components/Dashboard/DeployedLinks/DeployedLinks";
import { RepositoryContextProvider } from "../../lib/context/RepositoryContext/RepositoryContextProvider";
import { DashboardDeployTable } from "../../components/Dashboard/DashboardDeployTable/DashboardDeployTable";

import "./Dashboard.scss";

export const Dashboard = () => {
  return (
    <div className="Dashboard">
      <RepositoryContextProvider repositoryID="16584241">
        <PageTable title="Deploy">
          <DashboardDeployTable />
        </PageTable>
        <DeployedLinks />
      </RepositoryContextProvider>
    </div>
  );
};

import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

import { useRepositoryContext } from "../../../lib/context/RepositoryContext/RepositoryContext";

import { Branch } from "../../../proto/internaltool";

interface Props {
  value: string;
  onChange: (branch: string) => void;
}

export const BranchSelect = (props: Props) => {
  const { branches, requestBranches } = useRepositoryContext();

  return (
    <FormGroup>
      <Label for="repository">Select branch/tag:</Label>
      <Input
        type="select"
        id="repository"
        name="repository"
        value={props.value}
        onClick={() => {
          if (branches.length > 0) {
            return;
          }

          requestBranches();
        }}
        onChange={(e) => props.onChange(e.target.value)}
      >
        {branches.length > 0 ? (
          <BranchList branches={branches} />
        ) : (
          <>
            <option disabled value="">
              -
            </option>
            <option disabled value="loading">
              loading
            </option>
          </>
        )}
      </Input>
    </FormGroup>
  );
};

interface BranchListProps {
  branches: Branch[];
}

const BranchList = ({ branches }: BranchListProps) => {
  return (
    <>
      <option value="">-</option>
      {branches.map((item: Branch) => (
        <option key={`${item.name}-dashboard-listing`} value={item.name}>
          {item.name}
        </option>
      ))}
    </>
  );
};

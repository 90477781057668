import React from "react";
import {
  Alert,
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { NavLink } from "react-router-dom";

import { useResetPasswordFormik } from "./lib/useResetPasswordFormik";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";

import { CenteredBox } from "../../components/CenteredBox/CenteredBox";

import "./ResetPassword.scss";

export const ResetPassword = () => {
  const authCtx = useAuthContext();
  const formik = useResetPasswordFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values, formikHelpers) => {
      authCtx.resetPassword(values.email);
    },
  });

  return (
    <div className="ResetPassword w-100 h-100 align-items-center justify-center">
      <CenteredBox constant="GOGET Internal tool">
        <Form
          onSubmit={formik.handleSubmit}
          className="Contact__form d-flex flex-column w-100 p-4"
        >
          {authCtx.message.type && (
            <Alert color={authCtx.message.type}>{authCtx.message.text}</Alert>
          )}

          <FormGroup className="mb-1">
            <Label for="email">Username</Label>
            <Input
              type="email"
              id="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              invalid={!!formik.errors.email && formik.touched.email}
            />
            <FormFeedback>{formik.errors.email}</FormFeedback>
          </FormGroup>
          <NavLink className="mb-3 d-inline-block ml-auto" to="/login">
            Go to login page
          </NavLink>
          <Button
            disabled={authCtx.loading}
            type="submit"
            color="primary"
            className="w-100"
          >
            {authCtx.loading
              ? "Sending reset password"
              : "Send reset password email"}
          </Button>
        </Form>
      </CenteredBox>
    </div>
  );
};

import React, { useState } from "react";
import { Alert, Button, Form, FormGroup, Input, Label } from "reactstrap";
import { useRepositoryContext } from "../../../lib/context/RepositoryContext/RepositoryContext";

import { BranchSelect } from "../../shared/BranchSelect/BranchSelect";

import "./DashboardDeployTable.scss";

export const DashboardDeployTable = () => {
  const [type, setType] = useState("");
  const [branch, setBranch] = useState("");
  const [previewName, setPreviewName] = useState("");
  const [expireTime, setExpireTime] = useState("default");
  const { deployDashboard, message, loading } = useRepositoryContext();

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    deployDashboard(type, branch, previewName, expireTime);

    resetValues();
  };

  const resetValues = () => {
    setType("");
    setBranch("");
    setPreviewName("");
    setExpireTime("default");
  };

  return (
    <div className="DashboardDeployTable">
      {message.text && <Alert color={message.type}>{message.text}</Alert>}

      <Form
        onSubmit={handleFormSubmit}
        className="DashboardDeployTable__form w-100"
      >
        <div className="DashboardDeployTable__form__inputs">
          <BranchSelect
            value={branch}
            onChange={(branch: string) => setBranch(branch)}
          />
          <FormGroup>
            <Label for="deployType">Select type of deployment:</Label>
            <Input
              value={type}
              type="select"
              id="deployType"
              name="deployType"
              onChange={(e) => setType(e.target.value)}
            >
              <option value="">-</option>
              <option value="live">Live</option>
              <option value="preview">Preview</option>
            </Input>
          </FormGroup>
          {type === "preview" && (
            <>
              <FormGroup>
                <Label for="previewName">Set preview name:</Label>
                <Input
                  type="text"
                  id="previewName"
                  name="previewName"
                  value={previewName}
                  onChange={(e) => setPreviewName(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="expireTime">Set expire time:</Label>
                <Input
                  type="select"
                  id="expireTime"
                  name="expireTime"
                  value={expireTime}
                  onChange={(e) => setExpireTime(e.target.value)}
                >
                  <option value="default">Default</option>
                  <option value="1h">1h</option>
                  <option value="2h">2h</option>
                  <option value="3h">3h</option>
                  <option value="4h">4h</option>
                </Input>
              </FormGroup>
            </>
          )}
        </div>
        <Button
          color="primary"
          className="px-3"
          disabled={
            loading ||
            branch.length === 0 ||
            type.length === 0 ||
            (type === "preview" && previewName.length === 0)
          }
        >
          {loading ? "Deploying..." : "Deploy"}
        </Button>
      </Form>
    </div>
  );
};

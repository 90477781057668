import React, { useState } from "react";
import { Alert, Button, Form, FormGroup, Input, Label } from "reactstrap";
import { useRepositoryContext } from "../../../lib/context/RepositoryContext/RepositoryContext";

import "./DownloadRDXTable.scss";

export type ReleaseType = "release" | "beta";

export const DownloadRDXTable = () => {
  const [error, setError] = useState("");
  const [release, setRelease] = useState("");
  const [buildType, setBuildType] = useState("");
  const { downloadRDXVersion } = useRepositoryContext();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // if (release !== "release" && release !== "beta") {
    //   setError("Please select the release type");
    //   return;
    // }

    const url = await downloadRDXVersion();

    window.open(url.url, "_blank");

    resetForm();
  };

  const resetForm = () => {
    setRelease("");
    setBuildType("");
    setError("");
  };

  return (
    <div className="DownloadRDXTable">
      <Form className="DownloadRDXTable__form" onSubmit={handleSubmit}>
        {error && <Alert color="danger" title={error} />}

        <div className="DownloadRDXTable__form__inputs w-100">
          <FormGroup>
            <Label for="buildType">Choose build type:</Label>
            <Input
              type="select"
              name="select"
              id="buildType"
              value={buildType}
              onChange={(e) => setBuildType(e.target.value)}
            >
              <option value="">-</option>
              <option value="beta">Beta</option>
              <option value="release">Release</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="buildType">Choose release:</Label>
            <Input
              type="select"
              name="select"
              id="buildType"
              value={release}
              onChange={(e) => setRelease(e.target.value)}
            >
              <option value="">-</option>
              <option value="v.1.2.0">v.1.2.0</option>
              <option value="v.1.3.0">v.1.3.0</option>
            </Input>
          </FormGroup>
        </div>
        <Button
          color="primary"
          className="px-3"
          disabled={release.length === 0 || buildType.length === 0}
        >
          Download
        </Button>
      </Form>
    </div>
  );
};

import React from "react";

import { PageTable } from "../../components/shared/PageTable/PageTable";

import { BuildRDXTable } from "../../components/RDXPage/BuildRDXTable/BuildRDXTable";
import { DownloadRDXTable } from "../../components/RDXPage/DownloadRDXTable/DownloadRDXTable";
import { RepositoryContextProvider } from "../../lib/context/RepositoryContext/RepositoryContextProvider";

import "./RDXPage.scss";

export const RDXPage = () => {
  return (
    <div className="RDX">
      <RepositoryContextProvider repositoryID="20751904">
        <PageTable title="Build">
          <BuildRDXTable />
        </PageTable>
        <PageTable title="Download">
          <DownloadRDXTable />
        </PageTable>
      </RepositoryContextProvider>
    </div>
  );
};

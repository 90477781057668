import React, { useEffect, useState } from "react";
import classname from "classnames";

import "./StructureTable.scss";

export type StructureTableColumn = {
  header: string;
  key: string;
  classes?: string;
  sortable?: boolean;
};

type StructureTableRows = {
  [key: string]: React.ReactNode;
};

interface StructureTableProps {
  columns: StructureTableColumn[];
  rows: StructureTableRows[];
  loading?: boolean;
  className?: string;
  noResultsPlaceholder?: string | React.ReactNode;
}

export const StructureTable = (props: StructureTableProps) => {
  const [rows, setRows] = useState<StructureTableRows[]>(props.rows);
  const [columns, setColumns] = useState<StructureTableColumn[]>(props.columns);

  useEffect(() => {
    setRows(props.rows);
    setColumns(props.columns);
  }, [props.rows, props.columns]);

  if (rows && rows.length === 0) {
    return <h4>{props.noResultsPlaceholder}</h4>;
  }

  return (
    <div className={classname("StructureTable", props.className)}>
      <div className="StructureTable__head px-4 mb-1 mt-2">
        {columns.map((col: StructureTableColumn) => (
          <h5
            key={col.key}
            className={`StructureTable__head__item ${
              col.classes ? col.classes : ""
            }`}
          >
            {col.header}
          </h5>
        ))}
      </div>
      <div className="StructureTable__content">
        {rows.map((row, i) => (
          <div
            key={`row-${i}`}
            className="StructureTable__item p-4 bg-white d-flex rounded mb-3 shadow-sm"
          >
            {columns.map((rowColumn) => (
              <div
                key={rowColumn.key}
                className="StructureTable__item__column d-flex flex-column justify-content-center"
              >
                {row[rowColumn["key"]]}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

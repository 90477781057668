import React from "react";
import cs from "classnames";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./MicroservicesItem.scss";

interface Props {
  title?: string;
  available: boolean;
}

export const MicroservicesItem = (props: Props) => {
  const classes = cs("MicroservicesItem", "border p-3", {
    "border-danger": !props.available,
  });

  return (
    <div className={classes}>
      <div className="MicroservicesItem__header mb-3 d-flex align-items-center justify-content-center">
        <h3 className="mr-auto">{props.title || "Calendar provider"}</h3>
        {props.available ? (
          <FontAwesomeIcon icon={faCheckCircle} size="2x" color="#01ec96" />
        ) : (
          <FontAwesomeIcon icon={faTimesCircle} size="2x" color="#e24a1a" />
        )}
      </div>
      <MicroservicesLabel title="Latest commit" value="Nov 15, 2020 17:49" />
      <MicroservicesLabel title="Branch" value="master" />
      <MicroservicesLabel title="Commit hash" value="#712b0602" />
      <MicroservicesLabel
        title="Datetime of build"
        value="Nov 15, 2020 17:50"
      />
      <MicroservicesLabel title="Version" value="1.2.0" />
    </div>
  );
};

interface LabelProps {
  title: string;
  value: string;
}

export const MicroservicesLabel = (props: LabelProps) => {
  return (
    <div className="MicroservicesLabel mb-2 d-flex align-items-center">
      <h4 className="MicroservicesLabel__title mr-1">{props.title}:</h4>
      <h5 className="MicroservicesLabel__value">{props.value}</h5>
    </div>
  );
};

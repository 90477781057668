import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useAuthContext } from "./context/AuthContext/AuthContext";

export const AdminRoute = (props: RouteProps) => {
  const authCtx = useAuthContext();

  if (!authCtx.isAuthenticated || authCtx.user.role !== "admin") {
    return <Redirect to="/" />;
  }

  return <Route {...props} />;
};

// import { useState } from "react";

function parseData(data: any) {
  try {
    return JSON.parse(data);
  } catch {
    return null;
  }
}

export function useLocalStorage(item: string) {
  function getItem() {
    const localData = localStorage.getItem(item);

    if (!localData) {
      return null;
    }

    return parseData(localData);
  }

  function setItem(value: string | number | object) {
    if (typeof value === "object") {
      const stringValue = JSON.stringify(value);
      localStorage.setItem(item, stringValue);
      return;
    }

    localStorage.setItem(item, value.toString());
  }

  function removeItem() {
    if (!getItem()) {
      return;
    }

    localStorage.removeItem(item);
  }

  return {
    getItem,
    setItem,
    removeItem
  };
}

import React from "react";
import {
  StructureTable,
  StructureTableColumn,
} from "../../shared/StructureTable/StructureTable";

import "./DeployedLinks.scss";

export const DeployedLinks = () => {
  const columns: StructureTableColumn[] = [
    {
      key: "name",
      header: "Name",
    },
    {
      key: "type",
      header: "Type",
    },
    {
      key: "deployed_on",
      header: "Deployed on",
    },
    {
      key: "expires_at",
      header: "Expires at",
    },
  ];

  const rows = [
    {
      name: (
        <span>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://platform2-dev-273013--dashboard-cloud-build-w3cmp2wn.web.app/login"
          >
            Preview link 1
          </a>
        </span>
      ),
      type: <span>preview</span>,
      deployed_on: <span>Nov 15, 2020 17:50</span>,
      expires_at: <span>Nov 17, 2020 17:50</span>,
    },
    {
      name: (
        <span>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://platform2-dev-273013--dashboard-cloud-build-w3cmp2wn.web.app/login"
          >
            Live version
          </a>
        </span>
      ),
      type: <span>live</span>,
      deployed_on: <span>Nov 15, 2020 17:50</span>,
      expires_at: <span>-</span>,
    },
    {
      name: (
        <span>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://platform2-dev-273013--dashboard-cloud-build-w3cmp2wn.web.app/login"
          >
            Preview link 2
          </a>
        </span>
      ),
      type: <span>preview</span>,
      deployed_on: <span>Nov 15, 2020 17:50</span>,
      expires_at: <span>Nov 17, 2020 17:50</span>,
    },
    {
      name: (
        <span>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://platform2-dev-273013--dashboard-cloud-build-w3cmp2wn.web.app/login"
          >
            Preview link 3
          </a>
        </span>
      ),
      type: <span>preview</span>,
      deployed_on: <span>Nov 15, 2020 17:50</span>,
      expires_at: <span>Nov 17, 2020 17:50</span>,
    },
    {
      name: (
        <span>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://platform2-dev-273013--dashboard-cloud-build-w3cmp2wn.web.app/login"
          >
            Preview link 4
          </a>
        </span>
      ),
      type: <span>preview</span>,
      deployed_on: <span>Nov 15, 2020 17:50</span>,
      expires_at: <span>Nov 17, 2020 17:50</span>,
    },
    {
      name: (
        <span>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://platform2-dev-273013--dashboard-cloud-build-w3cmp2wn.web.app/login"
          >
            Preview link 5
          </a>
        </span>
      ),
      type: <span>preview</span>,
      deployed_on: <span>Nov 15, 2020 17:50</span>,
      expires_at: <span>Nov 17, 2020 17:50</span>,
    },
    {
      name: (
        <span>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://platform2-dev-273013--dashboard-cloud-build-w3cmp2wn.web.app/login"
          >
            Preview link 6
          </a>
        </span>
      ),
      type: <span>preview</span>,
      deployed_on: <span>Nov 15, 2020 17:50</span>,
      expires_at: <span>Nov 17, 2020 17:50</span>,
    },
  ];

  return (
    <div className="DeployedLinks mt-5">
      <h3 className="mb-4 pb-2 border-bottom">Deployed Versions</h3>
      <StructureTable columns={columns} rows={rows} />
    </div>
  );
};

import React, { useState } from "react";
import { Button } from "reactstrap";
import { BranchSelect } from "../../shared/BranchSelect/BranchSelect";

import "./BuildRDXTable.scss";

export const BuildRDXTable = () => {
  const [value, setValue] = useState("");

  const handleClick = () => {
    console.log("build value", { value });
  };

  return (
    <div className="BuildRDXTable">
      <BranchSelect value={value} onChange={(branch) => setValue(branch)} />
      <Button
        type="submit"
        color="primary"
        className="px-3"
        onClick={handleClick}
        disabled={value.length === 0}
      >
        Build branch
      </Button>
    </div>
  );
};

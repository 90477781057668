import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee, IconDefinition } from "@fortawesome/free-solid-svg-icons";

import "./SidebarMainMenuItem.scss";

interface Props {
  title: string;
  path: string;
  icon?: IconDefinition;
}

export const SidebarMainMenuItem = (props: Props) => {
  return (
    <li className="SidebarMainMenuItem">
      <NavLink
        to={props.path}
        isActive={(match, location) => {
          return location.pathname === props.path;
        }}
        className="d-flex flex-column align-items-center border-bottom p-4"
      >
        <div className="SidebarMainMenuItem__icon">
          <FontAwesomeIcon icon={props.icon || faCoffee} />
        </div>
        <h5 className="SidebarMainMenuItem__title px-2 mb-0">{props.title}</h5>
      </NavLink>
    </li>
  );
};

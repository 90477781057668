import React, { useState } from "react";
import { Alert, Button, Form, FormGroup, Input, Label } from "reactstrap";
import { GrpcWebFetchTransport } from "@protobuf-ts/grpcweb-transport";

import { EmailClient } from "../../proto/comms";

import { PageTable } from "../../components/shared/PageTable/PageTable";
import { ResponseMessage } from "../../lib/context/AuthContext/AuthContext";

import "./AdminPage.scss";

export const AdminPage = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState<ResponseMessage>({
    type: undefined,
    text: undefined,
  });

  let transport = new GrpcWebFetchTransport({
    format: "binary",
    baseUrl: "https://internaltools-gateway-ft3ogbbfma-ew.a.run.app:443",
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await sendInviteEmail();

    resetForm();
  };

  const resetForm = () => {
    setEmail("");
  };

  const sendInviteEmail = async () => {
    const client = new EmailClient(transport);

    try {
      await client.sendEmail({
        to: email,
        subject: "Invite to be a Resller for GOGET AB Smart Office",
        action: "invite",
        body: "This email was generated from the internal tool!",
      });

      setMessage({
        type: "success",
        text: "Invite was succesfully sent!",
      });
    } catch (error) {
      setMessage({
        type: "danger",
        text: "Email wasn't delivered, please try again!",
      });

      console.error(error.message);
    } finally {
      setTimeout(() => {
        setMessage({
          type: undefined,
          text: undefined,
        });
      }, 5000);
    }
  };

  return (
    <div className="AdminPage w-50">
      <PageTable title="Admin Page">
        {message.text && (
          <Alert className="w-100 mb-3" color={message.type}>
            {message.text}
          </Alert>
        )}
        <Form
          onSubmit={(e) => handleSubmit(e)}
          className="Contact__form d-flex w-100 align-items-center pr-4"
        >
          <FormGroup className="w-50">
            <Label for="AdminPageEmail">Email</Label>
            <Input
              type="email"
              value={email}
              id="AdminPageEmail"
              name="AdminPageEmail"
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
          <Button
            type="submit"
            color="primary"
            className="AdminPage__submit ml-4"
            disabled={email.trim().length === 0}
          >
            Invite
          </Button>
        </Form>
      </PageTable>
    </div>
  );
};

import React from "react";

import "./PageTable.scss";

interface Props {
  title: string;
  children: React.ReactNode;
}

export const PageTable = (props: Props) => {
  return (
    <div className="PageTable bg-white border border-radius rounded p-3 mb-4">
      <h3 className="mb-4 pb-2 border-bottom">{props.title}</h3>
      {props.children}
    </div>
  );
};

// @generated by protobuf-ts 1.0.8 with parameters generate_dependencies
// @generated from protobuf file "comms.proto" (syntax proto3)
// tslint:disable
import { RpcTransport } from "@protobuf-ts/runtime-rpc";
import { MethodInfo } from "@protobuf-ts/runtime-rpc";
import { BinaryWriteOptions } from "@protobuf-ts/runtime";
import { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import { BinaryReadOptions } from "@protobuf-ts/runtime";
import { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import { UnaryCall } from "@protobuf-ts/runtime-rpc";
import { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * SendEmailRequest
 *
 * @generated from protobuf message SendEmailRequest
 */
export interface SendEmailRequest {
    /**
     * @generated from protobuf field: string to = 1;
     */
    to: string;
    /**
     * @generated from protobuf field: string subject = 2;
     */
    subject: string;
    /**
     * @generated from protobuf field: string body = 3;
     */
    body: string;
    /**
     * @generated from protobuf field: string action = 4;
     */
    action: string; // Should this be a enum?
}
/**
 * SendEmailResponse
 *
 * @generated from protobuf message SendEmailResponse
 */
export interface SendEmailResponse {
    /**
     * @generated from protobuf field: string response = 1;
     */
    response: string;
}
/**
 * @generated from protobuf service Email
 */
export interface IEmailClient {
    /**
     * @generated from protobuf rpc: SendEmail(SendEmailRequest) returns (SendEmailResponse);
     */
    sendEmail(input: SendEmailRequest, options?: RpcOptions): UnaryCall<SendEmailRequest, SendEmailResponse>;
}
/**
 * Type for protobuf message SendEmailRequest
 */
class SendEmailRequest$Type extends MessageType<SendEmailRequest> {
    constructor() {
        super("SendEmailRequest", [
            { no: 1, name: "to", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "subject", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "body", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "action", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SendEmailRequest>): SendEmailRequest {
        const message = { to: "", subject: "", body: "", action: "" };
        if (value !== undefined)
            reflectionMergePartial<SendEmailRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendEmailRequest): SendEmailRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string to */ 1:
                    message.to = reader.string();
                    break;
                case /* string subject */ 2:
                    message.subject = reader.string();
                    break;
                case /* string body */ 3:
                    message.body = reader.string();
                    break;
                case /* string action */ 4:
                    message.action = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendEmailRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string to = 1; */
        if (message.to !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.to);
        /* string subject = 2; */
        if (message.subject !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.subject);
        /* string body = 3; */
        if (message.body !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.body);
        /* string action = 4; */
        if (message.action !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.action);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const SendEmailRequest = new SendEmailRequest$Type();
/**
 * Type for protobuf message SendEmailResponse
 */
class SendEmailResponse$Type extends MessageType<SendEmailResponse> {
    constructor() {
        super("SendEmailResponse", [
            { no: 1, name: "response", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SendEmailResponse>): SendEmailResponse {
        const message = { response: "" };
        if (value !== undefined)
            reflectionMergePartial<SendEmailResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendEmailResponse): SendEmailResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string response */ 1:
                    message.response = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendEmailResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string response = 1; */
        if (message.response !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.response);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const SendEmailResponse = new SendEmailResponse$Type();
/**
 * @generated from protobuf service Email
 */
export class EmailClient implements IEmailClient {
    readonly typeName = "Email";
    readonly methods: MethodInfo[] = [
        { service: this, name: "SendEmail", localName: "sendEmail", I: SendEmailRequest, O: SendEmailResponse, options: { "google.api.http": { body: "*", post: "/v1/emails:send" } } }
    ];
    constructor(private readonly _transport: RpcTransport) {
    }
    sendEmail(input: SendEmailRequest, options?: RpcOptions): UnaryCall<SendEmailRequest, SendEmailResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options), i = method.I.create(input);
        return stackIntercept<SendEmailRequest, SendEmailResponse>("unary", this._transport, method, opt, i);
    }
}

// @generated by protobuf-ts 1.0.8 with parameters generate_dependencies
// @generated from protobuf file "internaltool.proto" (syntax proto3)
// tslint:disable
import { RpcTransport } from "@protobuf-ts/runtime-rpc";
import { MethodInfo } from "@protobuf-ts/runtime-rpc";
import { BinaryWriteOptions } from "@protobuf-ts/runtime";
import { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import { BinaryReadOptions } from "@protobuf-ts/runtime";
import { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import { UnaryCall } from "@protobuf-ts/runtime-rpc";
import { RpcOptions } from "@protobuf-ts/runtime-rpc";
import { Timestamp } from "./google\\protobuf\\timestamp";
/**
 * Auth messages
 *
 * @generated from protobuf message GetTenantRequest
 */
export interface GetTenantRequest {
    /**
     * The tenant to retrieve.
     * Format: tenants/{tenant}
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string email = 2;
     */
    email: string;
}
/**
 * @generated from protobuf message Tenant
 */
export interface Tenant {
    /**
     * The resource name of the tenant
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * The tenant id of the tenant
     *
     * @generated from protobuf field: string tenant_id = 2;
     */
    tenantId: string;
}
/**
 * @generated from protobuf message ListBranchesRequest
 */
export interface ListBranchesRequest {
    /**
     * The parent, which owns this collection of branches.
     * Format: projects/{project}
     *
     * @generated from protobuf field: string parent = 1;
     */
    parent: string;
    /**
     * The maximum number of branches to return. The service may return fewer than
     * this value.
     * If unspecified, at most 50 branches will be returned.
     * The maximum value is 1000; values above 1000 will be coerced to 1000.
     *
     * @generated from protobuf field: int32 page_size = 2;
     */
    pageSize: number;
    /**
     * A page token, received from a previous `ListBranches` call.
     * Provide this to retrieve the subsequent page.
     *
     * When paginating, all other parameters provided to `ListBranches` must match
     * the call that provided the page token.
     *
     * @generated from protobuf field: string page_token = 3;
     */
    pageToken: string;
}
/**
 * @generated from protobuf message ListBranchesResponse
 */
export interface ListBranchesResponse {
    /**
     * The branches from the specified repository.
     *
     * @generated from protobuf field: repeated Branch branches = 1;
     */
    branches: Branch[];
    /**
     * A token, which can be sent as `page_token` to retrieve the next page.
     * If this field is omitted, there are no subsequent pages.
     *
     * @generated from protobuf field: string next_page_token = 2;
     */
    nextPageToken: string;
}
/**
 * @generated from protobuf message Branch
 */
export interface Branch {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
}
/**
 * @generated from protobuf message ListTagsRequest
 */
export interface ListTagsRequest {
    /**
     * The parent, which owns this collection of tags.
     * Format: projects/{project}
     *
     * @generated from protobuf field: string parent = 1;
     */
    parent: string;
    /**
     * The maximum number of tags to return. The service may return fewer than
     * this value.
     * If unspecified, at most 50 branches will be returned.
     * The maximum value is 1000; values above 1000 will be coerced to 1000.
     *
     * @generated from protobuf field: int32 page_size = 2;
     */
    pageSize: number;
    /**
     * A page token, received from a previous `ListTags` call.
     * Provide this to retrieve the subsequent page.
     *
     * When paginating, all other parameters provided to `ListTags` must match
     * the call that provided the page token.
     *
     * @generated from protobuf field: string page_token = 3;
     */
    pageToken: string;
}
/**
 * @generated from protobuf message ListTagsResponse
 */
export interface ListTagsResponse {
    /**
     * The branches from the specified repository.
     *
     * @generated from protobuf field: repeated Tag tags = 1;
     */
    tags: Tag[];
    /**
     * A token, which can be sent as `page_token` to retrieve the next page.
     * If this field is omitted, there are no subsequent pages.
     *
     * @generated from protobuf field: string next_page_token = 2;
     */
    nextPageToken: string;
}
/**
 * @generated from protobuf message Tag
 */
export interface Tag {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
}
/**
 * @generated from protobuf message GetBuildRequest
 */
export interface GetBuildRequest {
    /**
     * The product type you want to get a build status for.
     *
     * @generated from protobuf field: string product = 1;
     */
    product: string;
    /**
     * The Cloud Build ID reference received from creating a build.
     *
     * @generated from protobuf field: string build_id = 2;
     */
    buildId: string;
}
/**
 * @generated from protobuf message CreateBuildRequest
 */
export interface CreateBuildRequest {
    /**
     * The parent resource where this build will be created.
     * Format: builder/{product}
     *
     * @generated from protobuf field: string product = 1;
     */
    product: string;
    /**
     * Build options to customize a build.
     *
     * @generated from protobuf field: BuildOptions buildOptions = 2;
     */
    buildOptions?: BuildOptions;
}
/**
 * Build Options for customizing a build.
 *
 * @generated from protobuf message BuildOptions
 */
export interface BuildOptions {
    /**
     * Branch to use. If nothing selected, defaults to master branch.
     *
     * @generated from protobuf field: string branch = 1;
     */
    branch: string;
    /**
     * Whether to deploy dashboard live or on a preview channel. Is ignored for RDX.
     *
     * @generated from protobuf field: bool production = 2;
     */
    production: boolean;
    /**
     * Preview channel for dashboard Firebase deploy.
     *
     * @generated from protobuf field: string previewChannel = 3;
     */
    previewChannel: string;
    /**
     * Expiry time for the preview channel in minutes or hours. Format: 20m, 1h
     *
     * @generated from protobuf field: string expiryTime = 4;
     */
    expiryTime: string;
    /**
     * Semantic version of the deployed application.
     *
     * @generated from protobuf field: string version = 5;
     */
    version: string;
}
/**
 * A representation of a build of the product.
 *
 * @generated from protobuf message Build
 */
export interface Build {
    /**
     * The resource name of the product build.
     * Format: builders/{product}/build/{build}
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * The Google Cloud Build ID used for referencing the build.
     *
     * @generated from protobuf field: string build_id = 2;
     */
    buildId: string;
    /**
     * The status of the build. Returns SUCCESS, FAILURE, QUEUED or WORKING.
     *
     * @generated from protobuf field: string status = 3;
     */
    status: string;
}
/**
 * @generated from protobuf message ListReleasesRequest
 */
export interface ListReleasesRequest {
    /**
     * The parent, which owns this collection of releases.
     * Format: projects/{project}
     *
     * @generated from protobuf field: string parent = 1;
     */
    parent: string;
    /**
     * @generated from protobuf field: string product = 2;
     */
    product: string;
    /**
     * @generated from protobuf field: string channel = 3;
     */
    channel: string;
    /**
     * The maximum number of releases to return. The service may return fewer than
     * this value.
     * If unspecified, at most 50 branches will be returned.
     * The maximum value is 1000; values above 1000 will be coerced to 1000.
     *
     * @generated from protobuf field: int32 page_size = 4;
     */
    pageSize: number;
    /**
     * A page token, received from a previous `ListBranches` call.
     * Provide this to retrieve the subsequent page.
     *
     * When paginating, all other parameters provided to `ListBranches` must match
     * the call that provided the page token.
     *
     * @generated from protobuf field: string page_token = 5;
     */
    pageToken: string;
}
/**
 * @generated from protobuf message ListReleasesResponse
 */
export interface ListReleasesResponse {
    /**
     * The branches from the specified repository.
     *
     * @generated from protobuf field: repeated Release releases = 1;
     */
    releases: Release[];
    /**
     * A token, which can be sent as `page_token` to retrieve the next page.
     * If this field is omitted, there are no subsequent pages.
     *
     * @generated from protobuf field: string next_page_token = 2;
     */
    nextPageToken: string;
}
/**
 * @generated from protobuf message Release
 */
export interface Release {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string url = 2;
     */
    url: string;
}
/**
 * @generated from protobuf message GetServiceInfoRequest
 */
export interface GetServiceInfoRequest {
    /**
     * The service info to retrieve.
     * Format: services/{service}
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
}
/**
 * @generated from protobuf message ServiceInfo
 */
export interface ServiceInfo {
    /**
     * @generated from protobuf field: string git_commit = 1;
     */
    gitCommit: string;
    /**
     * @generated from protobuf field: string git_branch = 2;
     */
    gitBranch: string;
    /**
     * @generated from protobuf field: string build_id = 3;
     */
    buildId: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp build_datetime = 4;
     */
    buildDatetime?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp commit_datetime = 5;
     */
    commitDatetime?: Timestamp;
    /**
     * @generated from protobuf field: string version = 6;
     */
    version: string;
}
/**
 * @generated from protobuf service Auth
 */
export interface IAuthClient {
    /**
     * Retrieves the tenant based on a user email
     *
     * @generated from protobuf rpc: GetTenant(GetTenantRequest) returns (Tenant);
     */
    getTenant(input: GetTenantRequest, options?: RpcOptions): UnaryCall<GetTenantRequest, Tenant>;
}
/**
 * @generated from protobuf service Repository
 */
export interface IRepositoryClient {
    /**
     * Retrieves the published branches on the repository based on project Parent ID
     *
     * @generated from protobuf rpc: ListBranches(ListBranchesRequest) returns (ListBranchesResponse);
     */
    listBranches(input: ListBranchesRequest, options?: RpcOptions): UnaryCall<ListBranchesRequest, ListBranchesResponse>;
    /**
     * Retrieves the published tags on the repository based on project Parent ID
     *
     * @generated from protobuf rpc: ListTags(ListTagsRequest) returns (ListTagsResponse);
     */
    listTags(input: ListTagsRequest, options?: RpcOptions): UnaryCall<ListTagsRequest, ListTagsResponse>;
}
/**
 * @generated from protobuf service Builder
 */
export interface IBuilderClient {
    /**
     * @generated from protobuf rpc: CreateBuild(CreateBuildRequest) returns (Build);
     */
    createBuild(input: CreateBuildRequest, options?: RpcOptions): UnaryCall<CreateBuildRequest, Build>;
    /**
     * @generated from protobuf rpc: GetBuild(GetBuildRequest) returns (Build);
     */
    getBuild(input: GetBuildRequest, options?: RpcOptions): UnaryCall<GetBuildRequest, Build>;
}
/**
 * @generated from protobuf service Downloader
 */
export interface IDownloaderClient {
    /**
     * Retrieves the published products
     *
     * @generated from protobuf rpc: ListReleases(ListReleasesRequest) returns (ListReleasesResponse);
     */
    listReleases(input: ListReleasesRequest, options?: RpcOptions): UnaryCall<ListReleasesRequest, ListReleasesResponse>;
}
/**
 * @generated from protobuf service Info
 */
export interface IInfoClient {
    /**
     * @generated from protobuf rpc: GetServiceInfo(GetServiceInfoRequest) returns (ServiceInfo);
     */
    getServiceInfo(input: GetServiceInfoRequest, options?: RpcOptions): UnaryCall<GetServiceInfoRequest, ServiceInfo>;
}
/**
 * Type for protobuf message GetTenantRequest
 */
class GetTenantRequest$Type extends MessageType<GetTenantRequest> {
    constructor() {
        super("GetTenantRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.resource_reference": { type: "auth/Tenant" }, "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetTenantRequest>): GetTenantRequest {
        const message = { name: "", email: "" };
        if (value !== undefined)
            reflectionMergePartial<GetTenantRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTenantRequest): GetTenantRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string email */ 2:
                    message.email = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTenantRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string email = 2; */
        if (message.email !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.email);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const GetTenantRequest = new GetTenantRequest$Type();
/**
 * Type for protobuf message Tenant
 */
class Tenant$Type extends MessageType<Tenant> {
    constructor() {
        super("Tenant", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "tenant_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Tenant>): Tenant {
        const message = { name: "", tenantId: "" };
        if (value !== undefined)
            reflectionMergePartial<Tenant>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Tenant): Tenant {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string tenant_id */ 2:
                    message.tenantId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Tenant, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string tenant_id = 2; */
        if (message.tenantId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.tenantId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const Tenant = new Tenant$Type();
/**
 * Type for protobuf message ListBranchesRequest
 */
class ListBranchesRequest$Type extends MessageType<ListBranchesRequest> {
    constructor() {
        super("ListBranchesRequest", [
            { no: 1, name: "parent", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.resource_reference": { childType: "repository/Branch" }, "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListBranchesRequest>): ListBranchesRequest {
        const message = { parent: "", pageSize: 0, pageToken: "" };
        if (value !== undefined)
            reflectionMergePartial<ListBranchesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListBranchesRequest): ListBranchesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string parent */ 1:
                    message.parent = reader.string();
                    break;
                case /* int32 page_size */ 2:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 3:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListBranchesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string parent = 1; */
        if (message.parent !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.parent);
        /* int32 page_size = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        /* string page_token = 3; */
        if (message.pageToken !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const ListBranchesRequest = new ListBranchesRequest$Type();
/**
 * Type for protobuf message ListBranchesResponse
 */
class ListBranchesResponse$Type extends MessageType<ListBranchesResponse> {
    constructor() {
        super("ListBranchesResponse", [
            { no: 1, name: "branches", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Branch },
            { no: 2, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListBranchesResponse>): ListBranchesResponse {
        const message = { branches: [], nextPageToken: "" };
        if (value !== undefined)
            reflectionMergePartial<ListBranchesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListBranchesResponse): ListBranchesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Branch branches */ 1:
                    message.branches.push(Branch.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string next_page_token */ 2:
                    message.nextPageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListBranchesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Branch branches = 1; */
        for (let i = 0; i < message.branches.length; i++)
            Branch.internalBinaryWrite(message.branches[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string next_page_token = 2; */
        if (message.nextPageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nextPageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const ListBranchesResponse = new ListBranchesResponse$Type();
/**
 * Type for protobuf message Branch
 */
class Branch$Type extends MessageType<Branch> {
    constructor() {
        super("Branch", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Branch>): Branch {
        const message = { name: "" };
        if (value !== undefined)
            reflectionMergePartial<Branch>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Branch): Branch {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Branch, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const Branch = new Branch$Type();
/**
 * Type for protobuf message ListTagsRequest
 */
class ListTagsRequest$Type extends MessageType<ListTagsRequest> {
    constructor() {
        super("ListTagsRequest", [
            { no: 1, name: "parent", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.resource_reference": { childType: "repository/Tag" }, "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListTagsRequest>): ListTagsRequest {
        const message = { parent: "", pageSize: 0, pageToken: "" };
        if (value !== undefined)
            reflectionMergePartial<ListTagsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListTagsRequest): ListTagsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string parent */ 1:
                    message.parent = reader.string();
                    break;
                case /* int32 page_size */ 2:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 3:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListTagsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string parent = 1; */
        if (message.parent !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.parent);
        /* int32 page_size = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        /* string page_token = 3; */
        if (message.pageToken !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const ListTagsRequest = new ListTagsRequest$Type();
/**
 * Type for protobuf message ListTagsResponse
 */
class ListTagsResponse$Type extends MessageType<ListTagsResponse> {
    constructor() {
        super("ListTagsResponse", [
            { no: 1, name: "tags", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Tag },
            { no: 2, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListTagsResponse>): ListTagsResponse {
        const message = { tags: [], nextPageToken: "" };
        if (value !== undefined)
            reflectionMergePartial<ListTagsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListTagsResponse): ListTagsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Tag tags */ 1:
                    message.tags.push(Tag.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string next_page_token */ 2:
                    message.nextPageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListTagsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Tag tags = 1; */
        for (let i = 0; i < message.tags.length; i++)
            Tag.internalBinaryWrite(message.tags[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string next_page_token = 2; */
        if (message.nextPageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nextPageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const ListTagsResponse = new ListTagsResponse$Type();
/**
 * Type for protobuf message Tag
 */
class Tag$Type extends MessageType<Tag> {
    constructor() {
        super("Tag", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Tag>): Tag {
        const message = { name: "" };
        if (value !== undefined)
            reflectionMergePartial<Tag>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Tag): Tag {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Tag, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const Tag = new Tag$Type();
/**
 * Type for protobuf message GetBuildRequest
 */
class GetBuildRequest$Type extends MessageType<GetBuildRequest> {
    constructor() {
        super("GetBuildRequest", [
            { no: 1, name: "product", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "build_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetBuildRequest>): GetBuildRequest {
        const message = { product: "", buildId: "" };
        if (value !== undefined)
            reflectionMergePartial<GetBuildRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBuildRequest): GetBuildRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string product */ 1:
                    message.product = reader.string();
                    break;
                case /* string build_id */ 2:
                    message.buildId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBuildRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string product = 1; */
        if (message.product !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.product);
        /* string build_id = 2; */
        if (message.buildId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.buildId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const GetBuildRequest = new GetBuildRequest$Type();
/**
 * Type for protobuf message CreateBuildRequest
 */
class CreateBuildRequest$Type extends MessageType<CreateBuildRequest> {
    constructor() {
        super("CreateBuildRequest", [
            { no: 1, name: "product", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.resource_reference": { childType: "builder/BuildOptions" }, "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "buildOptions", kind: "message", T: () => BuildOptions }
        ]);
    }
    create(value?: PartialMessage<CreateBuildRequest>): CreateBuildRequest {
        const message = { product: "" };
        if (value !== undefined)
            reflectionMergePartial<CreateBuildRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateBuildRequest): CreateBuildRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string product */ 1:
                    message.product = reader.string();
                    break;
                case /* BuildOptions buildOptions */ 2:
                    message.buildOptions = BuildOptions.internalBinaryRead(reader, reader.uint32(), options, message.buildOptions);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateBuildRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string product = 1; */
        if (message.product !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.product);
        /* BuildOptions buildOptions = 2; */
        if (message.buildOptions)
            BuildOptions.internalBinaryWrite(message.buildOptions, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const CreateBuildRequest = new CreateBuildRequest$Type();
/**
 * Type for protobuf message BuildOptions
 */
class BuildOptions$Type extends MessageType<BuildOptions> {
    constructor() {
        super("BuildOptions", [
            { no: 1, name: "branch", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "production", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "previewChannel", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "expiryTime", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BuildOptions>): BuildOptions {
        const message = { branch: "", production: false, previewChannel: "", expiryTime: "", version: "" };
        if (value !== undefined)
            reflectionMergePartial<BuildOptions>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BuildOptions): BuildOptions {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string branch */ 1:
                    message.branch = reader.string();
                    break;
                case /* bool production */ 2:
                    message.production = reader.bool();
                    break;
                case /* string previewChannel */ 3:
                    message.previewChannel = reader.string();
                    break;
                case /* string expiryTime */ 4:
                    message.expiryTime = reader.string();
                    break;
                case /* string version */ 5:
                    message.version = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BuildOptions, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string branch = 1; */
        if (message.branch !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.branch);
        /* bool production = 2; */
        if (message.production !== false)
            writer.tag(2, WireType.Varint).bool(message.production);
        /* string previewChannel = 3; */
        if (message.previewChannel !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.previewChannel);
        /* string expiryTime = 4; */
        if (message.expiryTime !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.expiryTime);
        /* string version = 5; */
        if (message.version !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.version);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const BuildOptions = new BuildOptions$Type();
/**
 * Type for protobuf message Build
 */
class Build$Type extends MessageType<Build> {
    constructor() {
        super("Build", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "build_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "status", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Build>): Build {
        const message = { name: "", buildId: "", status: "" };
        if (value !== undefined)
            reflectionMergePartial<Build>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Build): Build {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string build_id */ 2:
                    message.buildId = reader.string();
                    break;
                case /* string status */ 3:
                    message.status = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Build, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string build_id = 2; */
        if (message.buildId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.buildId);
        /* string status = 3; */
        if (message.status !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const Build = new Build$Type();
/**
 * Type for protobuf message ListReleasesRequest
 */
class ListReleasesRequest$Type extends MessageType<ListReleasesRequest> {
    constructor() {
        super("ListReleasesRequest", [
            { no: 1, name: "parent", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.resource_reference": { childType: "downloader/Release" }, "google.api.field_behavior": ["REQUIRED"] } },
            { no: 2, name: "product", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "channel", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListReleasesRequest>): ListReleasesRequest {
        const message = { parent: "", product: "", channel: "", pageSize: 0, pageToken: "" };
        if (value !== undefined)
            reflectionMergePartial<ListReleasesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListReleasesRequest): ListReleasesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string parent */ 1:
                    message.parent = reader.string();
                    break;
                case /* string product */ 2:
                    message.product = reader.string();
                    break;
                case /* string channel */ 3:
                    message.channel = reader.string();
                    break;
                case /* int32 page_size */ 4:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 5:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListReleasesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string parent = 1; */
        if (message.parent !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.parent);
        /* string product = 2; */
        if (message.product !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.product);
        /* string channel = 3; */
        if (message.channel !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.channel);
        /* int32 page_size = 4; */
        if (message.pageSize !== 0)
            writer.tag(4, WireType.Varint).int32(message.pageSize);
        /* string page_token = 5; */
        if (message.pageToken !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const ListReleasesRequest = new ListReleasesRequest$Type();
/**
 * Type for protobuf message ListReleasesResponse
 */
class ListReleasesResponse$Type extends MessageType<ListReleasesResponse> {
    constructor() {
        super("ListReleasesResponse", [
            { no: 1, name: "releases", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Release },
            { no: 2, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListReleasesResponse>): ListReleasesResponse {
        const message = { releases: [], nextPageToken: "" };
        if (value !== undefined)
            reflectionMergePartial<ListReleasesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListReleasesResponse): ListReleasesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated Release releases */ 1:
                    message.releases.push(Release.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string next_page_token */ 2:
                    message.nextPageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListReleasesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated Release releases = 1; */
        for (let i = 0; i < message.releases.length; i++)
            Release.internalBinaryWrite(message.releases[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string next_page_token = 2; */
        if (message.nextPageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nextPageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const ListReleasesResponse = new ListReleasesResponse$Type();
/**
 * Type for protobuf message Release
 */
class Release$Type extends MessageType<Release> {
    constructor() {
        super("Release", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Release>): Release {
        const message = { name: "", url: "" };
        if (value !== undefined)
            reflectionMergePartial<Release>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Release): Release {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string url */ 2:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Release, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string url = 2; */
        if (message.url !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const Release = new Release$Type();
/**
 * Type for protobuf message GetServiceInfoRequest
 */
class GetServiceInfoRequest$Type extends MessageType<GetServiceInfoRequest> {
    constructor() {
        super("GetServiceInfoRequest", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.resource_reference": { type: "info/ServiceInfo" }, "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<GetServiceInfoRequest>): GetServiceInfoRequest {
        const message = { name: "" };
        if (value !== undefined)
            reflectionMergePartial<GetServiceInfoRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetServiceInfoRequest): GetServiceInfoRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetServiceInfoRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const GetServiceInfoRequest = new GetServiceInfoRequest$Type();
/**
 * Type for protobuf message ServiceInfo
 */
class ServiceInfo$Type extends MessageType<ServiceInfo> {
    constructor() {
        super("ServiceInfo", [
            { no: 1, name: "git_commit", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "git_branch", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "build_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "build_datetime", kind: "message", T: () => Timestamp },
            { no: 5, name: "commit_datetime", kind: "message", T: () => Timestamp },
            { no: 6, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ServiceInfo>): ServiceInfo {
        const message = { gitCommit: "", gitBranch: "", buildId: "", version: "" };
        if (value !== undefined)
            reflectionMergePartial<ServiceInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ServiceInfo): ServiceInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string git_commit */ 1:
                    message.gitCommit = reader.string();
                    break;
                case /* string git_branch */ 2:
                    message.gitBranch = reader.string();
                    break;
                case /* string build_id */ 3:
                    message.buildId = reader.string();
                    break;
                case /* google.protobuf.Timestamp build_datetime */ 4:
                    message.buildDatetime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.buildDatetime);
                    break;
                case /* google.protobuf.Timestamp commit_datetime */ 5:
                    message.commitDatetime = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.commitDatetime);
                    break;
                case /* string version */ 6:
                    message.version = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ServiceInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string git_commit = 1; */
        if (message.gitCommit !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.gitCommit);
        /* string git_branch = 2; */
        if (message.gitBranch !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.gitBranch);
        /* string build_id = 3; */
        if (message.buildId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.buildId);
        /* google.protobuf.Timestamp build_datetime = 4; */
        if (message.buildDatetime)
            Timestamp.internalBinaryWrite(message.buildDatetime, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp commit_datetime = 5; */
        if (message.commitDatetime)
            Timestamp.internalBinaryWrite(message.commitDatetime, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string version = 6; */
        if (message.version !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.version);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
export const ServiceInfo = new ServiceInfo$Type();
/**
 * @generated from protobuf service Auth
 */
export class AuthClient implements IAuthClient {
    readonly typeName = "Auth";
    readonly methods: MethodInfo[] = [
        { service: this, name: "GetTenant", localName: "getTenant", I: GetTenantRequest, O: Tenant, options: { "google.api.http": { get: "/v1/tenants/{name}" }, "google.api.method_signature": ["name"] } }
    ];
    constructor(private readonly _transport: RpcTransport) {
    }
    getTenant(input: GetTenantRequest, options?: RpcOptions): UnaryCall<GetTenantRequest, Tenant> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options), i = method.I.create(input);
        return stackIntercept<GetTenantRequest, Tenant>("unary", this._transport, method, opt, i);
    }
}
/**
 * @generated from protobuf service Repository
 */
export class RepositoryClient implements IRepositoryClient {
    readonly typeName = "Repository";
    readonly methods: MethodInfo[] = [
        { service: this, name: "ListBranches", localName: "listBranches", I: ListBranchesRequest, O: ListBranchesResponse, options: { "google.api.http": { get: "/v1/projects/{parent}/branches" }, "google.api.method_signature": ["parent"] } },
        { service: this, name: "ListTags", localName: "listTags", I: ListTagsRequest, O: ListTagsResponse, options: { "google.api.http": { get: "/v1/projects/{parent}/tags" }, "google.api.method_signature": ["parent"] } }
    ];
    constructor(private readonly _transport: RpcTransport) {
    }
    listBranches(input: ListBranchesRequest, options?: RpcOptions): UnaryCall<ListBranchesRequest, ListBranchesResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options), i = method.I.create(input);
        return stackIntercept<ListBranchesRequest, ListBranchesResponse>("unary", this._transport, method, opt, i);
    }
    listTags(input: ListTagsRequest, options?: RpcOptions): UnaryCall<ListTagsRequest, ListTagsResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options), i = method.I.create(input);
        return stackIntercept<ListTagsRequest, ListTagsResponse>("unary", this._transport, method, opt, i);
    }
}
/**
 * @generated from protobuf service Builder
 */
export class BuilderClient implements IBuilderClient {
    readonly typeName = "Builder";
    readonly methods: MethodInfo[] = [
        { service: this, name: "CreateBuild", localName: "createBuild", I: CreateBuildRequest, O: Build, options: { "google.api.http": { body: "buildOptions", post: "/v1/builders/{product}" }, "google.api.method_signature": ["product,buildOptions"] } },
        { service: this, name: "GetBuild", localName: "getBuild", I: GetBuildRequest, O: Build, options: { "google.api.http": { get: "/v1/builders/{product}/build/{build_id}" }, "google.api.method_signature": ["product,build_id"] } }
    ];
    constructor(private readonly _transport: RpcTransport) {
    }
    createBuild(input: CreateBuildRequest, options?: RpcOptions): UnaryCall<CreateBuildRequest, Build> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options), i = method.I.create(input);
        return stackIntercept<CreateBuildRequest, Build>("unary", this._transport, method, opt, i);
    }
    getBuild(input: GetBuildRequest, options?: RpcOptions): UnaryCall<GetBuildRequest, Build> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options), i = method.I.create(input);
        return stackIntercept<GetBuildRequest, Build>("unary", this._transport, method, opt, i);
    }
}
/**
 * @generated from protobuf service Downloader
 */
export class DownloaderClient implements IDownloaderClient {
    readonly typeName = "Downloader";
    readonly methods: MethodInfo[] = [
        { service: this, name: "ListReleases", localName: "listReleases", I: ListReleasesRequest, O: ListReleasesResponse, options: { "google.api.http": { get: "/v1/products/{product}/{channel}" }, "google.api.method_signature": ["product,channel"] } }
    ];
    constructor(private readonly _transport: RpcTransport) {
    }
    listReleases(input: ListReleasesRequest, options?: RpcOptions): UnaryCall<ListReleasesRequest, ListReleasesResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options), i = method.I.create(input);
        return stackIntercept<ListReleasesRequest, ListReleasesResponse>("unary", this._transport, method, opt, i);
    }
}
/**
 * @generated from protobuf service Info
 */
export class InfoClient implements IInfoClient {
    readonly typeName = "Info";
    readonly methods: MethodInfo[] = [
        { service: this, name: "GetServiceInfo", localName: "getServiceInfo", I: GetServiceInfoRequest, O: ServiceInfo, options: { "google.api.http": { get: "/v1/services/{name}" }, "google.api.method_signature": ["name"] } }
    ];
    constructor(private readonly _transport: RpcTransport) {
    }
    getServiceInfo(input: GetServiceInfoRequest, options?: RpcOptions): UnaryCall<GetServiceInfoRequest, ServiceInfo> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options), i = method.I.create(input);
        return stackIntercept<GetServiceInfoRequest, ServiceInfo>("unary", this._transport, method, opt, i);
    }
}

import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { Header } from "../Header/Header";
import { Sidebar } from "../Sidebar/Sidebar";
import { AdminRoute } from "../../lib/AdminRoute";

import { useAuthContext } from "../../lib/context/AuthContext/AuthContext";

import { RDXPage } from "../../views/RDXPage/RDXPage";
import { General } from "../../views/General/General";
import { AdminPage } from "../../views/AdminPage/AdminPage";
import { ErrorPage } from "../../views/ErrorPage/ErrorPage";
import { Dashboard } from "../../views/Dashboard/Dashboard";
import { Microservices } from "../../views/Microservices/Microservices";

export const MainApp = () => {
  const authCtx = useAuthContext();

  if (!authCtx.isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <Header />
      <Sidebar />

      <div className="p-4">
        <Switch>
          <Route path="/" exact component={General} />
          <Route path="/rdx" exact component={RDXPage} />
          <Route path="/dashboard" exact component={Dashboard} />
          <Route path="/microservices" exact component={Microservices} />
          <AdminRoute path="/admin-page" exact component={AdminPage} />
          <Route component={ErrorPage} />
        </Switch>
      </div>
    </>
  );
};

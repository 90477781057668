import React from "react";
import { SidebarMainMenu } from "./SidebarMainMenu/SidebarMainMenu";

import "./Sidebar.scss";

export const Sidebar = () => {
  return (
    <div className="Sidebar">
      <div className="Sidebar__menu_container border-right">
        <SidebarMainMenu />
      </div>
    </div>
  );
};

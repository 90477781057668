import React from "react";

import { useAuthContext } from "../../../lib/context/AuthContext/AuthContext";

import { SidebarMainMenuItem } from "./SidebarMainMenuItem/SidebarMainMenuItem";

import "./SidebarMainMenu.scss";

export const SidebarMainMenu = () => {
  const authCtx = useAuthContext();

  return (
    <ul className="SidebarMainMenu">
      <SidebarMainMenuItem title="General" path="/" />
      <SidebarMainMenuItem title="Dashboard" path="/dashboard" />
      <SidebarMainMenuItem title="RDX" path="/rdx" />
      <SidebarMainMenuItem title="Microservices" path="/microservices" />
      {authCtx.user.role === "admin" && (
        <SidebarMainMenuItem title="Admin page" path="/admin-page" />
      )}
    </ul>
  );
};

import React from "react";
import { MicroservicesItem } from "../../components/Microservices/MicroservicesItem/MicroservicesItem";
import { MicroservicesHeader } from "../../components/MicroservicesHeader/MicroservicesHeader";

import "./Microservices.scss";

export const Microservices = () => {
  return (
    <div className="Microservices">
      <MicroservicesHeader />
      <div className="Microservices__list">
        <MicroservicesItem title="Service 1" available={false} />
        <MicroservicesItem title="Service 2" available={true} />
        <MicroservicesItem title="Service 3" available={true} />
        <MicroservicesItem title="Service 4" available={true} />
        <MicroservicesItem title="Service 5" available={true} />
        <MicroservicesItem title="Service 6" available={false} />
        <MicroservicesItem title="Service 7" available={false} />
        <MicroservicesItem title="Service 8" available={true} />
        <MicroservicesItem title="Service 9" available={false} />
        <MicroservicesItem title="Service 10" available={false} />
        <MicroservicesItem title="Service 11" available={false} />
        <MicroservicesItem title="Service 12" available={false} />
        <MicroservicesItem title="Service 13" available={true} />
        <MicroservicesItem title="Service 14" available={true} />
        <MicroservicesItem title="Service 15" available={true} />
        <MicroservicesItem title="Service 16" available={true} />
      </div>
    </div>
  );
};

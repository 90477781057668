import React, { useContext } from "react";
import { ResponseMessage } from "../AuthContext/AuthContext";

export interface RepositoryContextType {
  branches: any;
  loading: boolean;
  message: ResponseMessage;
  requestBranches: () => void;
  deployDashboard: (
    type: string,
    branch: string,
    previewName?: string,
    expireTime?: string
  ) => void;
  downloadRDXVersion: () => Promise<any>;
}

const initialValues: RepositoryContextType = {
  message: {
    type: undefined,
    text: undefined,
  },
  branches: {},
  loading: false,
  requestBranches: () => {},
  deployDashboard: () => {},
  downloadRDXVersion: async () => "",
};

export const RepositoryContext = React.createContext<RepositoryContextType>(
  initialValues
);

export const useRepositoryContext = () => useContext(RepositoryContext);

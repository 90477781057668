import { useFormik, FormikHelpers, FormikValues } from "formik";
import * as Yup from "yup";

const ResetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Username is required"),
});

export interface ResetPasswordFields {
  email: string;
}

interface UseResetPasswordFormOptions {
  initialValues?: ResetPasswordFields;
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<ResetPasswordFields>
  ) => Promise<any>;
}

export type ResetPasswordFormik = ReturnType<typeof useResetPasswordFormik>;

export const useResetPasswordFormik = (opts: UseResetPasswordFormOptions) => {
  return useFormik({
    initialValues: {
      email: "",
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
